import React from 'react';

// STYLES
import { home_hero_image_section_props } from './styles';

// MEDIA
import main_pic_01 from './../../../media/jpg/main_pic_01.jpg';
import main_pic_02 from './../../../media/jpg/main_pic_02.jpg';

// COMPONENTS
import Section from './../../atoms/section';
import HeroImage from './../../molecules/heroImage';
import AboutInfo from './../../molecules/aboutInfo';

export default function HomeHeroImageSection(props) {

  const { promo } = props;

  return (
    <Section {...home_hero_image_section_props(promo)}>
        <HeroImage promo={promo} picture={promo ? main_pic_02 : main_pic_01} />
        <AboutInfo promo={promo} alignSelf={promo ? 'flex-start' : null} />
    </Section>
  );
}
import React from 'react';
import { Image, Box } from 'rebass/styled-components';

// STYLES
import { hero_image_container_props } from './styles';

export default function HeroImage(props) {

  const { promo, picture } = props;

  return (
      <Box sx={hero_image_container_props(promo)}
      data-sal={promo ? 'slide-right' : 'slide-left'}
      data-sal-duration="600"
      data-sal-delay="0"
      data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
        <Image src={picture} alt='hero_image' />
      </Box>
  );
}

import React from 'react';
import { Flex, Link } from 'rebass/styled-components';
import { navigate } from '@reach/router';
import theme from './../../../gatsby-plugin-theme-ui';

// STYLES
import {
  about_info_container_props,
  border_one_props,
  border_two_props,
  border_three_props,
  border_four_props,
  heading_props,
  text_props,
  button_props
} from './styles';

// COMPONENTS
import Border from './../../atoms/border';
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';
import Button from './../../atoms/button';

export default function AboutInfo(props) {

  const { alignSelf, promo } = props;

  return (
    <Flex {...about_info_container_props(alignSelf)}
    data-sal={promo ? 'slide-left' : 'slide-right'}
    data-sal-duration="600"
    data-sal-delay="0"
    data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
        <Border {...border_one_props(promo)} />
        <Border {...border_two_props(promo)} />
        <Border {...border_three_props(promo)} />
        <Border {...border_four_props(promo)} />
        <Heading {...heading_props(promo)}>{`${promo ? 'Projektowanie Wnętrz' : 'Czym się zajmujemy?'}`}</Heading>
        {
          promo ?
          <>
          <Text {...text_props(promo)}>
            W naszej ofercie znajdą Państwo również kompleksową 
            usługę projektowania wnętrz.
          </Text>
          <Text {...text_props(promo)}>
          Po więcej szczegółów prosimy 
          o kontakt pod numerem <strong>667 929 189 </strong> 
          lub mailowo: 
          <Link sx={{ textDecoration: 'none', color: theme.colors.black }}
          href='mailto: kowalskawnetrza@gmail.com'>
            <strong> kowalskawnetrza@gmail.com.</strong>
          </Link>
          </Text>
          <Text {...text_props(promo)} margin='0 0 3.5rem 0'>
            Dla naszych klientów <strong>rabat 5%.</strong>
          </Text>
          </>
          :
          <>
          <Text {...text_props(promo)}>
            Firma Ardor sp. z o.o. istnieje od <strong>1996 roku.</strong>
          </Text>
          <Text {...text_props(promo)} margin='0 0 3.5rem 0'>
            Przez lata nasza firma nabywała doświadczenie oraz zdobywała zaufanie, zarówno klientów, 
            jak i sprzedawców. Świadczymy wysokiej jakości usługi w zakresie sprzedaży oraz budowy 
            kominków, pieców, piecyków na pellet, urządzeń na bio paliwo, kominków elektrycznych, 
            kominów oraz systemów ogrzewania.
          </Text>
          </>
        }
        {
          promo ? <Button anchor={{
            as: 'a',
            href: 'http://kajakowalska.pl',
            target: '_blank',
            rel: 'noopener noreferrer'
          }} {...button_props(promo, navigate)}>Kaja Kowalska</Button>: <Button {...button_props(promo, navigate)}>Czytaj dalej...</Button>
        }
    </Flex>
  );
}

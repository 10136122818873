
export const hero_image_container_props = (promo) => ({
    position: 'relative',
    left: promo ? null : -60,
    right: promo ? -60 : null,
    width: '100%',
    maxWidth: ['100%', 725],
    height: [300, 400],
    minHeight: 300,
    minWidth: 550,
    alignSelf: promo ? 'flex-end' : 'flex-start',
    '> img': {
        maxWidth: '100%',
        height: 'auto',

    }
})
import theme from './../../../gatsby-plugin-theme-ui';

// PROPS

export const home_service_item_props = (side) => ({
    width: '100%',
    maxWidth: ['100%', '100%', `calc(50% + 0.25rem)`],
    flexFlow: 'column nowrap',
    position: 'relative',
    alignSelf: side === 'left' ? 'flex-start' : 'flex-end'
});

export const border_props = side => ({
    position: {
        top: 0,
        left: side === 'left' ? 0 : null,
        right: side === 'right' ? 0 : null
    },
    transform: {
        transform: 'rotate(180deg)'
    },
    height: 250
});

export const dotted_line_props = (side, circle_quantity, with_arrow) => ({
    position: {
        top: 0,
        left: side === 'right' ? 0 : null,
        right: side === 'left' ? 0 : null
    },
    quantity: [...circle_quantity],
    with_arrow
})

export const icon_container_props = (number, side) => ({
    sx: {
        position: 'relative',
        width: 250,
        height: 250,
        flexFlow: 'column nowrap',
        alignSelf: side === 'left' ? 'flex-start' : 'flex-end',
        paddingLeft: side === 'left' ? ['2.5rem', '2.5rem', '3.5rem'] : null,
        paddingRight: side === 'right' ? ['2.5rem', '2.5rem', '3.5rem'] : null,
        ':before': {
            content: `"${number}"`,
            position: 'absolute',
            top: '0.5rem',
            left: side === 'right' ? '0.5rem' : null,
            right: side === 'left' ? '0.5rem' : null,
            zIndex: -1,
            fontSize: 88,
            fontWeight: theme.fontWeights.bold,
            fontFamily: theme.fonts.body,
            color: theme.colors.grey,
            opacity: 0.35
        }
    }
});

export const home_service_item_container_props = {
    sx: {
        width: '100%',
        margin: '5rem 0 0 0',
        ':nth-child(odd)': {
            flexFlow: ['column nowrap', 'column nowrap', 'row-reverse nowrap'],
            '> div > p': {
                padding: [0, 0, '0 3.5rem 0 1.5rem']
            }
        },
        ':nth-child(even)': {
            flexFlow: ['column nowrap', 'column nowrap', 'row nowrap'],
            '> div > p': {
                padding: [0, 0, '0 1.5rem 0 3.5rem']
            }
        }
    }
}

export const service_heading_props = side => ({
    as: 'h2',
    fontSize: [20, 20, 22],
    fontWeight: 700,
    margin: '2rem 0',
    textAlign: `${side === 'left' ? 'left' : 'right'}`
})

export const service_icon_props = (width, height, src, alt, side) => ({
    sx: {
        width,
        height,
        alignSelf: side === 'left' ? 'flex-start' : 'flex-end'
    },
    src,
    alt
})

export const service_description_container_props = {
    width: ['100%', '100%', 'calc(50% + 0.25rem)'],
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 600,
    margin: '0 auto'
}

export const service_description_props = {
    as: 'p',
    fontSize: [16, 16, 18],
    margin: ['3.5rem 0 0 0', '3.5rem 0 0 0', 0],
    textAlign: ['center', 'center', 'left']
}
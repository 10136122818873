import React from 'react'

// COMPONENTS
import SEO from './../components/templates/seo';
import HomeHeroImageSection from './../components/organisms/homeHeroImageSection';
import HomeServicesDisplaySection from './../components/organisms/homeServicesDisplaySection';
import HomeEcoSection from './../components/organisms/homeEcoSection';
import HomePromoSection from './../components/organisms/homePromoSection';

export default function Home(props) {
    return (
        <>
           <SEO title='Ardor: Piece kaflowe i na pellet, kominki - Bydgoszcz' />
           <HomeHeroImageSection />
           <HomeServicesDisplaySection />
           <HomeEcoSection />
           <HomePromoSection />
           <HomeHeroImageSection promo />
        </>
    )
}
import React from 'react'
import { Flex, Box } from 'rebass/styled-components';
import shortid from 'shortid';

// MEDIA
import Circle from './../../../media/svg/circle.inline.svg';
import Arrow from './../../../media/svg/arrow.inline.svg';

export default function DottedLine(props) {

    const { with_arrow, quantity, width, height, position, display, serviceAnimation } = props;

    const line_container = (width, height) => ({
        width: width ? width : 10,
        minHeight: height ? height : 150,
        flexFlow: 'column nowrap',
        alignItems: 'center',
        position: 'absolute',
        ...position,
        display
    });

    const circles = quantity.map((item, i) => 
        <Box key={shortid.generate()} sx={{ width: 5, height: 5, margin: '0.15rem auto', ':nth-child(11)': {
            margin: '0.5rem 0'
        } }}>
            <Circle />
        </Box>
    )

    return (
        <Flex sx={line_container(width, height)}
        data-sal={ serviceAnimation ? 'fade' : null }
        data-sal-duration="600"
        data-sal-delay="0"
        data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
            {circles}
            {with_arrow && 
            <Box sx={{ width: '100%', minHeight: 13, marginTop: '0.5rem' }}>
                <Arrow />
            </Box>
            }
        </Flex>
    )
}

// PROPS

export const about_info_container_props = (alignSelf) => ({
    sx: {
        position: 'relative',
        width: ['calc(100% - 1.5rem)', '60%'],
        maxWidth: ['100%', 550],
        height: 'auto',
        minHeight: 300,
        zIndex: 10,
        top: [-40, -200],
        flexFlow: 'column nowrap',
        alignSelf: alignSelf ? alignSelf : 'flex-end',
        background: '#FFF'
    }
})

export const border_props = (position, width, height, full, transform, display) => ({
    position,
    width,
    height,
    full,
    transform,
    display
});

export const border_one_props = promo => border_props({ top: 0 }, '100%', 10, true, { transform: promo ? 'rotate(180deg)' : null });
export const border_two_props = promo => border_props({ top: '1px', right: promo ? null : 0, left: promo ? 0 : null }, 10, 'calc(100% - 1px)', true, null, { display: ['none', 'none', 'inherit'] });
export const border_three_props = promo => border_props({ top: '1px', left: promo ? null : 0, right: promo ? 0 : null }, 10, '60%', null, { transform: 'rotate(180deg)' }, null);
export const border_four_props = promo => border_props({ bottom: 0, right: promo ? null : '1px', left: promo ? '1px' : null }, '45%', 10, null, { transform: promo ? null : 'rotate(180deg)' }, null);

export const heading_props = promo => ({
    as: 'h1',
    background_text: `${promo ? 'Projektowanie' : 'Czym się'}`,
    margin: [`4rem 0 2.5rem ${promo ? '1.25rem' : '2.5rem'}`, `4rem 0 2.5rem ${promo ? '1.25rem' : '3.5rem'}`, '4rem 0 2.5rem 3.5rem'],
    fontSize: [22, 24],
    textAlign: 'left'
})

export const text_props = promo => ({
    as: 'p',
    fontSize: [16, 16, 18],
    padding: [`0 ${promo ? '2.5rem' : '1.25rem'} 1.5rem ${promo ? '1.25rem' : '2.5rem'}`, `0 ${promo ? '3.5rem' : '1.25rem'} 1.5rem ${promo ? '1.25rem' : '3.5rem'}`, `0 3.5rem 1.5rem 3.5rem`]
})

export const button_props = (promo, navigate) => ({
    position: {
        position: 'absolute',
        bottom: 0,
        left: promo ? null : ['2rem', '3rem'],
        right: promo ? ['2rem', '3rem'] : null
    },
    padding: '0 0.5rem',
    onClick: promo ? null : () => navigate('/o-firmie')
})
import React from 'react'
import { Flex, Image } from 'rebass/styled-components';

// MEDIA
import EcoIcon from './../../../media/svg/eco_icon.svg'

// STYLES
import {
    home_eco_section_props,
    home_eco_icon_container_props,
    border_top_props,
    border_bottom_props,
    border_right_props,
    border_left_props,
    home_eco_icon_props,
    home_eco_heading_props,
    home_eco_text_props
} from './styles';

// COMPONENTS
import Section from './../../atoms/section';
import Border from './../../atoms/border';
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';

export default function HomeEcoSection(props) {
    return (
        <Section {...home_eco_section_props} animation={{
            'data-sal': 'fade',
            'data-sal-duration': '800',
            'data-sal-delay': '0',
            'data-sal-easing': 'cubic-bezier(0.165, 0.84, 0.44, 1)'
            }}>
            <Border {...border_top_props} />
            <Border {...border_bottom_props} />
            <Border {...border_left_props} />
            <Border {...border_right_props} />
            <Flex {...home_eco_icon_container_props}>
                <Image {...home_eco_icon_props(EcoIcon)} />
                <Heading {...home_eco_heading_props}>Eco Projekt</Heading>
            </Flex>
            <Text {...home_eco_text_props}>
                Z pełną świadomością możemy powiedzieć, 
                że nie przyczyniamy się do pogłębienia problemu smogu w naszej okolicy.
            </Text>
            <Text {...home_eco_text_props} margin='0 0 3.5rem 0'>
                <strong>Wszystkie urządzenia w naszej ofercie spełniają normy Eco-Projektu, 
                zatem są bezpieczne dla środowiska.</strong>
            </Text>
        </Section>
    )
}

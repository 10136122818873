import React from 'react'
import { Flex, Image} from 'rebass/styled-components';

// MEDIA
import Saloon from './../../../media/svg/saloon.svg';
import Tools from './../../../media/svg/tools.svg';
import Project from './../../../media/svg/project.svg';
import Fireplace from './../../../media/svg/fireplace.svg';

// STYLES
import {
    home_service_item_container_props,
    home_service_item_props,
    border_props,
    icon_container_props,
    service_heading_props,
    service_icon_props,
    dotted_line_props,
    service_description_container_props,
    service_description_props
} from './styles';

// COMPONENTS
import Border from './../../atoms/border';
import DottedLine from './../../atoms/dottedLine';
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';

export default function HomeServiceItem(props) {

    const { icon, title, text, border_side, number, circle_quantity, with_arrow } = props;

    return (
        <Flex {...home_service_item_container_props}>
            <Flex sx={home_service_item_props(border_side)}>
                <Border {...border_props(border_side)} serviceAnimation />
                <Flex {...icon_container_props(number, border_side)}
                data-sal='fade'
                data-sal-duration="600"
                data-sal-delay="200"
                data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
                <Heading {...service_heading_props(border_side)}>{title}</Heading>
                    {
                        icon === 'saloon' ? <Image {...service_icon_props(153.02, 140, Saloon, 'saloon_graphic', border_side)} /> :
                        icon === 'tools' ? <Image {...service_icon_props(138.4, 140, Tools, 'tools_graphic', border_side)} /> :
                        icon === 'project' ? <Image {...service_icon_props(158.67, 140, Project, 'project_graphic', border_side)} /> :
                        icon === 'fireplace' ? <Image {...service_icon_props(136.71, 140, Fireplace, 'fireplace_graphic', border_side)} /> : null
                    }
                </Flex>
                <DottedLine {...dotted_line_props(border_side, circle_quantity, with_arrow)} serviceAnimation />
            </Flex>
            <Flex {...service_description_container_props}
            data-sal='slide-up'
            data-sal-duration="600"
            data-sal-delay="600"
            data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
                <Text {...service_description_props}>{text}</Text>
            </Flex>
        </Flex>
    )
}

import React from 'react'

// STYLES
import {
    home_services_display_section_props,
    home_services_display_heading_props,
    home_service_item_props
} from './styles';

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import HomeServiceItem from './../../molecules/homeServiceItem';

export default function HomeServicesDisplaySection(props) {

    const circle_quantity = num => new Array(num);

    const text = {
        saloon: `Podczas wizyty w salonie prezentujemy nasze produkty, 
        dokonujemy wstępnych ustaleń w zakresie wyboru rodzaju 
        urządzenia grzewczego, mocy, jego przeznaczenia oraz 
        pożądanego przez Państwa efektu wizualnego.`,
        tools: `Następnie umawiamy się na szczegółowe 
        pomiary oraz sprawdzenie warunków technicznych 
        w miejscu, gdzie ma być zamontowane urządzenie.`,
        project: `Kolejnym etapem jest wykonanie projektu 
        technicznego oraz wizualizacji 3d kominka wraz zabudową. 
        Na postawie zaakceptowanego projektu robimy wycenę, 
        zawierającą konkretne propozycje wkładu, materiałów 
        montażowych i instalacyjnych oraz robocizny.`,
        fireplace: `Po ustaleniu wszystkich szczegółów, 
        przychodzi czas na realizację projektu -zamówienie 
        urządzenia grzewczego oraz jego instalację i wykonanie zabudowy kominkowej.`
    }

    return (
        <Section {...home_services_display_section_props}>
            <Heading {...home_services_display_heading_props}>Etapy Współpracy</Heading>
            <HomeServiceItem {...home_service_item_props(
                'saloon', circle_quantity(21), '01.',
                'Wizyta w salonie', 'left', text.saloon
            )} />
            <HomeServiceItem {...home_service_item_props(
                'tools', circle_quantity(21), '02.',
                'Pomiary u klienta', 'right', text.tools
            )} />
            <HomeServiceItem {...home_service_item_props(
                'project', circle_quantity(21), '03.',
                'Wykonanie projektu', 'left', text.project
            )} />
            <HomeServiceItem {...home_service_item_props(
                'fireplace', circle_quantity(11), '04.',
                'Realizacja zamówienia', 'right', text.fireplace, true
            )} />
        </Section>
    )
}

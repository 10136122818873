import React from 'react';
import { Flex, Image } from 'rebass/styled-components';
import { navigate } from '@reach/router';

// MEDIA
import promo_pic from './../../../media/png/promocje.png';

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';
import Button from './../../atoms/button';
import Border from './../../atoms/border';

export default function HomePromoSection() {

    const section_props = {
        sx: {
            display: 'flex',
            position: 'relative',
            flexFlow: ['column nowrap', 'row nowrap'],
            width: '100%',
            minHeight: 300,
            margin: ['5rem auto 0 auto', '0 auto calc(7.5rem + 200px) auto', '0 auto calc(7.5rem + 100px) auto'],
            '> img': {
                width: ['100%', '60%'],
                maxWidth: 530,
                height: 'auto',
                margin: ['0 auto', 0]
            },
            '> div:last-child': {
                position: ['relative', 'absolute'],
                flexFlow: 'column nowrap',
                justifyContent: 'center',
                width: '100%',
                maxWidth: ['100%', 550],
                height: 260,
                zIndex: 1,
                background: '#FFF',
                bottom: ['45px', -200, -100],
                right: ['auto', '1.25rem'],
                left: [0, 'auto'],
                padding: '2.5rem 1.25rem'
            }
        }
    }

    const border_props = (position, width, height, full, transform, display) => ({
        position,
        width,
        height,
        full,
        transform,
        display
    });

    return (
        <Section {...section_props}>
            <Image src={promo_pic} alt='promo_pic'
            data-sal='slide-left'
            data-sal-duration="600"
            data-sal-delay="0"
            data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)" />
            <Flex
            data-sal='slide-right'
            data-sal-duration="600"
            data-sal-delay="0"
            data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
                <Border {...border_props({ top: 0, left: 0 }, '100%', 10)} zIndex={2} />
                <Border {...border_props({ top: '50%', left: 0 }, 10, 'calc(100% - 15px)', true, { transform: 'translateY(-50%)' }, { display: ['none', 'inherit'] })}
                background='linear-gradient(35.52deg, #15100F -24.88%, #97021B 26.41%, #15100F 200.1%)' />
                <Border {...border_props({ bottom: 0, left: 0 }, '100%', 10)} zIndex={2} />
                <Heading background_text='Promocje' as='h1' fontSize={[22, 24]}>Nasze promocje</Heading>
                <Text textAlign='center' margin='1.5rem 0 2.5rem 0'>Zapraszamy do zapoznania się z naszą ofertą promocyjną.</Text>
                <Button onClick={() => navigate('/promocje')} margin='0 auto'>... Promocje ...</Button>
            </Flex>
        </Section>
    );
}

// PROPS

export const home_services_display_section_props = {
    sx: {
        width: '100%',
        minHeight: 600,
        flexFlow: 'column nowrap'
    },
    padding: true
}

export const home_services_display_heading_props = {
    as: 'h1',
    background_text: 'Współpraca'
}

export const home_service_item_props = 
        (icon, circle_quantity, number, title, border_side, text, with_arrow) => ({
    icon,
    circle_quantity,
    number,
    title,
    border_side,
    text,
    with_arrow
})
// PROPS

export const home_eco_section_props = {
    sx: {
        position: 'relative',
        width: ['100%', '100%', `calc(100% - (1.25rem *2))`],
        flexFlow: 'column nowrap',
        minHeight: 400,
        margin: '5rem auto',
    }
}

const border_props = (position, width, height, transform, display) => ({
    position, width, height, transform, display
})

export const border_top_props = border_props(
    { top: 0, left: 0 }, ['55%', '40%'], 10, null, null
)

export const border_bottom_props = border_props(
    { bottom: 0, right: 0 }, ['55%', '40%'], 10, { transform: 'rotate(180deg)' }, null
)

export const border_right_props = border_props(
    { bottom: 0, right: 0 }, 10, '45%',
    { transform: 'rotate(-360deg)' }, { display: ['none', 'none', 'inherit'] }
)

export const border_left_props = border_props(
    { top: '1px', left: 0 }, 10, '45%',
    { transform: 'rotate(180deg)' }, { display: ['none', 'none', 'inherit'] }
)

export const home_eco_icon_container_props = {
    sx: {
        alignItems: 'center',
        width: ['100%', '75%', '55%', '60%'],
        padding: [
            '3.5rem 1rem 1.5rem 1rem',
            '3.5rem 1.25rem 1.25rem 1.25rem',
            '4rem 2.5rem 1.25rem 2.5rem'
        ]
    }
}

export const home_eco_icon_props = icon => ({
    width: [111.66, 126.23, 135.93],
    height: [115, 130, 140],
    src: icon,
    alt: 'eco_icon'
})

export const home_eco_heading_props = {
    as: 'h1',
    background_text: 'Eco',
    margin: '0 0 0 1rem'
}

export const home_eco_text_props = {
    as: 'p',
    fontSize: [16, 16, 18],
    padding: [
        '1.25rem',
        '1.25rem calc(140px + 2.5rem) 1.25rem 1.25rem',
        '1.25rem calc(140px + 2.5rem) 1.25rem 2.5rem'
    ]
}